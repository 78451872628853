<template>
    <div class="col-12 text-center">
        <div v-if="iqDataError || notEnoughData">
            <!--Error-->
            <error></error>
            <p v-if="notEnoughData" class="alert alert-danger mt-5 text-center">{{$t('not-enough-data')}}</p>
            <p v-if="iqDataError" class="alert alert-danger mt-5 text-center">{{iqDataError}} {{$t('request-unsuccessful')}}</p>
        </div>

        <div id='cashier'></div>
    </div>
</template>

<script>
import store from "../../../store/shared_state";
import {getPaymentIQData} from "../../../services/backend.service";
import _PaymentIQCashier from "paymentiq-cashier-bootstrapper";

export default {
    name: 'PaymentIqDeposit',
    data(){
        return{
            notEnoughData: null,
            iqDataError: null,
            paymentIqData: null,
        }
    },
    components:{
        Error: () => import('../../others/Error'),
    },
    computed:{
        tokenData() { return store.state.tokenData },
    },
    mounted(){
        const tokenData = this.tokenData;

        console.log('-----STORE DATA-----')
        console.log(tokenData)

        if(tokenData && tokenData.customerId && tokenData.walletType && tokenData.ipAddress && tokenData.currencyCode && tokenData.amount && tokenData.extra && tokenData.extra.predefinedAmounts){
            this.getPaymentIqData();
        } else {
            this.notEnoughData = true;
        }
    },
    methods:{
        getPaymentIqData(){
            const data = {
                walletType: this.tokenData.walletType,
                customerId: this.tokenData.customerId,
                currency: this.tokenData.currencyCode,
                amount: this.tokenData.amount,
                ipAddress: this.tokenData.ipAddress,
                paymentType: 1, //1 means deposit
            };

            getPaymentIQData(data).then(res => {

                console.log('-----getPaymentIQData DATA-----')
                console.log(res.data);

                if(res.data.success && res.data.data){
                    let paymentIqData = res.data.data;
                    if(paymentIqData.providerType && paymentIqData.reciptPageUrl && paymentIqData.merchId){
                        this.paymentIqData = paymentIqData;
                        this.initPaymentIQPage();
                    } else {
                        this.notEnoughData = true;
                    }
                } else {
                    this.iqDataError = res.data.message && res.data.message !== "No message available" ? res.data.message : this.$t('error-2');
                }
            }).catch( e => {
                this.iqDataError = this.$t('error-2');
                console.log(`%c Error in PaymentIQDeposit.vue - method: getPaymentIQData - ${e}`, 'color:red');
            });
        },
        initPaymentIQPage() {
            let paymentIQCashier = new _PaymentIQCashier('#cashier',
                {
                    merchantId: this.paymentIqData.merchId,
                    userId: this.tokenData.customerId,
                    sessionId: this.tokenData.walletType,
                    environment: process.env.NODE_ENV !== 'production' ? 'test' : 'production', // if not set, defaults to production
                    method: 'deposit', // if not set, defaults to deposit
                    attributes: this.paymentIqData.attributes,
                    receiptBackBtn: true,
                    allowMobilePopup: true,
                    predefinedAmounts: JSON.parse(this.tokenData.extra.predefinedAmounts)
                },
                (api) => {
                    api.on({
                        cashierInitLoad: () => console.log('Cashier init load'),
                        update: data => {
                            console.log('The passed in data was set', data);
                        },
                        success: data => {
                            console.log('Transaction was completed successfully', data);
                            window.location = `${this.paymentIqData.reciptPageUrl}${data.data.payload.merchantTxId}&brandUrl=${this.tokenData.extra.brandUrl}`;
                        },
                        failure: data => {
                            console.log('Transaction failed', data);
                            // Delay added so that we can modify PIQ messages to show to the user
                            setTimeout(function() {
                                window.location = `${this.paymentIqData.reciptPageUrl}${data.data.merchantTxId}&brandUrl=${this.tokenData.extra.brandUrl}`;
                            }.bind(this), 5000);
                        },
                        isLoading: data => console.log('Data is loading', data),
                        doneLoading: data => console.log('Data has been successfully downloaded', data),
                        newProviderWindow: data => console.log('A new window / iframe has opened', data),
                        paymentMethodSelect: data => console.log('Payment method was selected', data),
                        paymentMethodPageEntered: data => console.log('New payment method page was opened', data),
                        navigate: data => console.log('Path navigation triggered', data)
                    })
                    api.set({
                        config: {
                            amount: this.tokenData.amount,
                            providerType: this.paymentIqData.providerType,
                            showBackAtInitPath: false
                        }
                    })
                    api.css(`.your-custom-css {color: blue;}`)
                }
            );
        }
    }
}
</script>

<style scoped></style>

